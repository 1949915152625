 // eslint-disable-next-line import/no-anonymous-default-export
 export default {
    en: {
        translation: {
            "en": "ع",
        },
    },
    ar: {
        translation: {
            "en": "en",
            "B": "ا",
            "lueboard": "lueboard",
            "logo":"بدج",
            "Account settings": "اعدادت الحساب",
            "Add another account": "اضافه حساب جديد",
            'Profile':"الصفحة الشخصية",
            "My account" :"حسابي",
            "Settings":"الاعدادات",
            "Logout":"تسجيل الخروج",
            "add":"اضافة",
            "search":"بحث"
        },
    },
};
